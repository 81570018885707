body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Jost', sans-serif !important;
}

a {
  text-decoration: none;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.blur {
  height: 100%;
  width: 100%;
}